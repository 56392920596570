import React from 'react';
import NavBar from '../components/Navbar/NavBar';
import Footer from '../components/Footer';
import { Link } from 'react-router-dom';
import { useDocTitle } from '../components/CustomHook';
import blog1 from '../images/blogs/blog.jpg'
import blog2 from '../images/blogs/blog2.jpg'
import blog3 from '../images/blogs/blog3.jpg'
const Blogs = () => {
    useDocTitle('Alem Immigration Services - Blogs');

    const blogs = [
        {
            id: 1,
            title: "ክብሪ መዝገብ እንታይ ኢዩ? ",
            description: "ናይ ክብሪ መዝገብ ንምውጻእ ዘድልዩ ሰነዳትን ከይዲ ስርሓትን ብዝርዝር ንፈልጥ",
            image: blog1,
            date: "March 1, 2024",
            link: "/blog/birth-certificate-process"
        },
        {
            id: 2,
            title: "ክብሪ መዝግብ ብኸመይ ይወጽእ?",
            description: "ናይ መርዓ ሰነድ ንምውጻእ ዘድልዩ ሰነዳትን ከይዲ ስርሓትን ብዝርዝር ንፈልጥ",
            image: blog2,
            date: "March 5, 2024",
            link: "/blog/marriage-certificate-process"
        },
        {
            id: 3,
            title: "ንምንታይ ከ ይጠቅም?",
            description: "ናይ ቪዛ ንምውጻእ ዘድልዩ ሰነዳትን ከይዲ ስርሓትን ብዝርዝር ንፈልጥ",
            image: blog3,
            date: "March 10, 2024",
            link: "/blog/visa-application-process"
        }
    ];

    return (
        <>
            <div>
                <NavBar />
            </div>
            <br></br><br></br>
            <div className="container mx-auto px-4 py-12">
            <br></br><br></br><br></br><br></br>
                 <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
                    {blogs.map((blog) => (
                        <div key={blog.id} className="bg-white rounded-lg shadow-lg overflow-hidden">
                            <img 
                                src={blog.image} 
                                alt={blog.title}
                                className="w-full h-48 object-cover"
                            />
                            <div className="p-6">
                                <p className="text-gray-600 text-sm mb-2">{blog.date}</p>
                                <h2 className="text-xl font-semibold mb-2">{blog.title}</h2>
                                <p className="text-gray-700 mb-4">{blog.description}</p>
                                <Link 
                                    to={blog.link}
                                    className="text-blue-900 hover:text-blue-700 font-semibold"
                                >
                                    Read More →
                                </Link>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            <Footer />
        </>
    );
};

export default Blogs; 