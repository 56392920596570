import React from 'react';
import { useParams, Link, useNavigate } from 'react-router-dom';
import NavBar from '../components/Navbar/NavBar';
import Footer from '../components/Footer';
import { useDocTitle } from '../components/CustomHook';

const BlogPost = () => {
    const { slug } = useParams();
    const navigate = useNavigate();
    useDocTitle(`Alem Immigration Services - Blog Post`);

    // This would typically come from an API or CMS
    const blogPosts = {
        'birth-certificate-process': {
            id: 1,
            title: "",
            content: `
            <br></br>
                <h2 class="text-2xl font-bold mb-4">ክብሪ መዝገብ እንታይ ማለት ኢዩ።</h2>
               <p>
ናይ ልደት ምስክር ወረቐ ወይ ብቋንቋ ትግርኛ ክብሪ መዝገብ ተባሂል ዝጽዋዕ ሰነድ፡ ብመንግስቲ ኤርትራ ህጻን ምስ ተወልደ ብወግዒ ንምምዝጋብ ዘውጽኦ መሰረታዊ ሕጋዊ ሰነድ’ዩ። ኣብ ኤርትራ እዚ ሰነድ እዚ ከም ኣገዳሲ መርትዖ መንነትን ዜግነትን ስድራቤታዊ መስመር ወለዶን ኮይኑ የገልግል። ብተለምዶ ምሉእ ስም ናይቲ ህጻን፡ ዕለትን ቦታን ልደት፡ ጾታን ኣስማት ክልቲኦም ወለዲን ዘጠቓልል ኮይኑ፡ ብመሰረት ሃገራዊ ሕጊ ኣፍልጦ ከም ዝረኽቡ የረጋግጽ። ብናይ ከባቢ ሲቪላዊ ምዝገባ ቤት ጽሕፈታት ወይ ብናይ ኮሙን ሰበስልጣን ዝወሃብ ምስክር ወረቐት ልደት፡ ምስ ሕጋዊ ቅርጻታት እታ ሃገር ብምስምማዕ፡ መሰል ዜጋታት ንምሕላው፡ እምነ ኩርናዕ ስርዓተ ሲቪላዊ ምዝገባ ኤርትራ’ዩ።

ኣብ ኤርትራ፡ ከም ክንክን ጥዕና፡ ትምህርቲ፡ ከምኡ’ውን መደባት ክታበት ዝኣመሰሉ ኣገደስቲ ኣገልግሎታት ንምርካብ፡ ምስክር ወረቐት ልደት ምውናን ወሳኒ እዩ። ብተወሳኺ ኣብ ዳሕረዋይ እዋን ብመንግስቲ ዝወሃብ መለለዪ መንነት፣ ፓስፖርት ፣ ን ድያስፖራ ንምልከቱ ጉዳያት ንምርካብ ግዴታ ኮይኑ፡ ሃገራዊ መለለዪ መንነት ወይ ፓስፖርት ሓዊሱ፡ ኣብ ከም ጥርዓን ውርሻ ወይ ምርግጋጽ ዜግነት ዝኣመሰሉ ሕጋዊ ጉዳያት ድማ ዓቢ ተራ ኣለዎ። ከተማታት መብዛሕትኡ ግዜ ኣብ እዋናዊ ምዝገባ ቀዳምነት ክህባ እንከለዋ፡ ኣብ ገጠር ዞባታት ብሰንኪ ሎጂስቲካዊ ዕንቅፋታት ብድሆታት ክቕጽሉ ይኽእሉ። ብባህላዊ መዳይ፡ እቲ ሰነድ ንባህላዊ ኣሰራርሓታት ምስ ዘመናዊ መንግስታዊ ጠለባት ዝድልድል ኮይኑ፡ ህጻናት ኣብ ውሽጢ ማሕበረሰቦምን ብሃገራዊ ትካላትን ኣፍልጦ ከም ዝረኽቡ የረጋግጽ።

ሕጋዊ መንነት ብምርግጋጽ፡ እቲ ናይ ልደት ምስክር ወረቐት፡ መሰል ትምህርቲ፡ ክንክን ጥዕና፡ ማሕበራዊ ተሳትፎ ህጻን ዝሕሉ ኮይኑ፡ ንዲሞግራፍያውን ምምሕዳራውን ውጥን ኤርትራ’ውን ይድግፍ። ምውናን ክብሪ መዝገብ ኣብ ዜግነታዊ ህይወትን ሓለዋን ኣብ ትሕቲ ኤርትራዊ ስርዓተ ኣገልግሎት ንኽኣቱ ግድን ይገብሮ።

               </p>
                <h2 class="text-2xl font-bold mb-4">What is birth Certificate?</h2>
               
                
A birth certificate, known as *Kbri Mezgeb* (ክብሪ መዝገብመዝገብ) in Tigrinya, is a foundational legal document issued by the Eritrean government to officially record a child’s birth. In Eritrea, this document serves as a vital proof of identity, citizenship, and familial lineage. It typically includes the child’s full name, date and place of birth, gender, and the names of both parents, ensuring their recognition under national law. Issued through local civil registration offices or municipal authorities, the birth certificate is a cornerstone of Eritrea’s civil registration system, aligning with the country’s legal frameworks to uphold citizens’ rights.  

In Eritrea, possessing a birth certificate is crucial for accessing essential services such as healthcare, education, and vaccination programs. It is also mandatory for obtaining government-issued identification later in life, including national IDs or passports, and plays a pivotal role in legal matters like inheritance claims or citizenship verification. While urban areas often prioritize timely registration, challenges may persist in rural regions due to logistical barriers. Culturally, the document bridges traditional practices with modern state requirements, ensuring children are recognized both within their communities and by national institutions.  

By affirming legal identity, the birth certificate safeguards a child’s rights to education, healthcare, and social participation, while also supporting Eritrea’s demographic and administrative planning. Ultimately, it is indispensable for inclusion in civic life and protection under Eritrean law.</p>
            `,
            date: "March 1, 2024",
            author: "Alem Immigration Services"
        },
        'marriage-certificate-process': {
            id: 2,
            title: "",
            content: `
            <br></br>
                <h2 class="text-2xl font-bold mb-4">ከመይ ጌርና ክብሪ መዝገብ ካብ ኤምባሲ ኤርትራ ንረክብ?</h2>
             <p>
             
ናይ ልደት ምስክር ወረቐት (ክብሪ መዝገብ) ኣብ ወጻኢ ዝተወልዱ ወይ ካብ ኤርትራ ወጻኢ ሰነዳት ዘድልዮም ኤርትራውያን ዜጋታት ብመንገዲ ኤምባሲ ኤርትራ ክረኽብዎ ይኽእሉ። እቲ መስርሕ፡ መመልከቲ ናብ ኤምባሲ ምቕራብ፡ ምስ መርትዖ ልደት ናይቲ ቆልዓ (ንኣብነት፡ መዝገብ ሆስፒታል ወይ ናይ ወጻኢ ምስክር ወረቐት ልደት)፡ ናይ ወለዲ ኤርትራዊ መንነት ሰነዳት (ሃገራዊ መንነት ወይ ፓስፖርት)፡ ከምኡ’ውን ኣድላዪ እንተኾይኑ ምስክር ወረቐት ሓዳር ተሰንዩ ዘጠቓልል እዩ። እቲ ኤምባሲ ነቲ ሓበሬታ የረጋግጾ፡ ምስ ሰብመዚ ሲቪላዊ ምዝገባ ኤርትራ ብምውህሃድ፡ ድሕሪ ምርግጋጽ ድማ ነቲ ሰነድ የውጽኦ። ካብ ኤርትራ ወጻኢ ንዝወለዱ፡ እቲ ኤምባሲ ምስ ኤርትራዊ መዐቀኒታት ክሰማምዑ ናይ ወጻኢ ሰነዳት ምትርጓምን ሕጋውነትን ክሓትት ይኽእል እዩ።
ብስደት ንዝወጹ  ልዕሊ ዕድመ ድማ ቆጸራ ብምሓዝ ኣብ ኤምባሲ ቀሪቦም ፎርም መሊኦም ሕጋዊ ውክልና ናብ ኤርትራ ብምስዳድ ኣብ ኤርትራ ተሰሪሑሎም ብመንገዲ ቆንስላዊ ጉዳያትን ኤምባሲ ኤርትራ ክርከብዎ ይኽእሉ።

             </p>
             <br></br>
                <h2 class="text-2xl font-bold mb-4">How to Obtain a Birth Certificate from the Embassy of Eritrea</h2>
             <p>
             
A birth certificate (ክብሪ መዝገብ) for Eritrean citizens born abroad or requiring documentation outside Eritrea can be acquired through the Embassy of Eritrea. The process involves submitting an application to the embassy, accompanied by proof of the child’s birth (e.g., a hospital record or foreign birth certificate), parents’ Eritrean identification documents (national ID or passport), and, if applicable, marriage certificates. The embassy verifies the information, coordinates with Eritrea’s civil registration authorities, and issues the document after authentication. For births outside Eritrea, the embassy may require translation and legalization of foreign documents to align with Eritrean standards.

The embassy acts as a critical intermediary, ensuring compliance with Eritrea’s legal requirements while addressing the needs of the diaspora. Parents or guardians must complete embassy-provided forms, pay applicable fees, and may need witnesses or affidavits in case of incomplete records. Processing times vary, but the certificate remains essential for securing citizenship, passports, or consular services. Challenges such as delays or documentation gaps may arise, particularly for older births or complex cases, necessitating direct communication with embassy officials.

Culturally, the process reinforces Eritrean identity among diaspora communities, linking children to their heritage and legal rights. The embassy-issued birth certificate ensures eligibility for education, healthcare, and future civic participation in Eritrea or abroad. By formalizing legal identity, it upholds the child’s rights under Eritrean law and strengthens ties to the homeland, reflecting the government’s commitment to its global citizenry. For specifics, contacting the nearest Eritrean embassy or consulate is advised to navigate requirements efficiently.
             </p>
            `,
            date: "March 5, 2024",
            author: "Alem Immigration Services"
        },
        'visa-application-process': {
            id: 3,
            title: "",
            content: `
                <br></br>
                <h2 class="text-2xl font-bold mb-4">ክብሪ መዝገብ ንምንታይ ይጠቅምይጠቅም</h2>
              <p>
              ክብሪ መዝገብ ንዝተፈላለዩ ኣብ ሃገረ ኤርትራ ናይ ዜግነታዊ መሰላት ንምሕታት ካብቶም ኣዝዮም ቀንድን መሰራትውን ሰነድ ኢዩ። እዚ 
              ሰነድ ድሕሪ ምሓዝ ናይ ሃገረ ኤርትራ ፓስፖርት፣ ናይ ኤርትራ መንነትን ምስኡ ዝተሓሓዙ ወረቃቅቲ ንምውጻእ የገልግል።።
              </p>
                
             
            `,
            date: "March 10, 2024",
            author: ""
        },
        'baptism-certificate-process': {
            id: 4,
            title: "ናይ ጥምቀት ወረቐት ምውጻእ",
            content: `
                <h2 class="text-2xl font-bold mb-4">ዘድልዩ ሰነዳት</h2>
                <ul class="list-disc pl-6 mb-6">
                    <li>ናይ ወለዲ መንነት ወረቐት</li>
                    <li>ናይ ቤተክርስትያን መረጋገጺ</li>
                </ul>
                <h2 class="text-2xl font-bold mb-4">ከይዲ ስርሓት</h2>
                <ol class="list-decimal pl-6 mb-6">
                    <li>ናብ ቤተክርስትያን ምኻድ</li>
                    <li>መረጋገጺ ምርካብ</li>
                    <li>ሰነድ ምውጻእ</li>
                </ol>
            `,
            date: "March 15, 2024",
            author: "Alem Immigration Services"
        },
        'education-certificate-process': {
            id: 5,
            title: "ናይ ትምህርቲ ሰነድ ምውጻእ",
            content: `
                <h2 class="text-2xl font-bold mb-4">ዘድልዩ ሰነዳት</h2>
                <ul class="list-disc pl-6 mb-6">
                    <li>ናይ መንነት ወረቐት</li>
                    <li>ናይ ቤት ትምህርቲ መረጋገጺ</li>
                    <li>ፎቶ</li>
                </ul>
                <h2 class="text-2xl font-bold mb-4">ከይዲ ስርሓት</h2>
                <ol class="list-decimal pl-6 mb-6">
                    <li>ናብ ቤት ትምህርቲ ምኻድ</li>
                    <li>መረጋገጺ ምርካብ</li>
                    <li>ናብ ሚኒስትሪ ትምህርቲ ምቕራብ</li>
                </ol>
            `,
            date: "March 20, 2024",
            author: "Alem Immigration Services"
        }
    };

    const post = blogPosts[slug];

    if (!post) {
        return (
            <>
                <NavBar />
                <div className="container mx-auto px-4 py-12">
                    <div className="text-center">
                        <h1 className="text-4xl font-bold text-red-600 mb-4">Blog post not found</h1>
                        <Link to="/blogs" className="text-blue-900 hover:text-blue-700">
                            ← Back to Blogs
                        </Link>
                    </div>
                </div>
                <Footer />
            </>
        );
    }

    // Get next and previous post slugs
    const slugs = Object.keys(blogPosts);
    const currentIndex = slugs.indexOf(slug);
    const prevSlug = currentIndex > 0 ? slugs[currentIndex - 1] : null;
    const nextSlug = currentIndex < slugs.length - 1 ? slugs[currentIndex + 1] : null;

    return (
        <>
            <NavBar />
            <div className="container mx-auto px-4 py-12">
                <article className="max-w-3xl mx-auto">
                    <Link to="/blogs" className="text-blue-900 hover:text-blue-700 mb-6 inline-block">
                        ← Back to Blogs
                    </Link>
                    <h1 className="text-4xl font-bold text-blue-900 mb-4">{post.title}</h1>
                    <div className="text-gray-600 mb-8">
                        <span>{post.date}</span> • <span>{post.author}</span>
                    </div>
                    <div 
                        className="prose lg:prose-xl mb-8"
                        dangerouslySetInnerHTML={{ __html: post.content }}
                    />
                    
                    {/* Navigation between posts */}
                    <div className="flex justify-between items-center mt-8 pt-4 border-t">
                        {prevSlug ? (
                            <Link 
                                to={`/blog/${prevSlug}`}
                                className="text-blue-900 hover:text-blue-700"
                            >
                                ← {blogPosts[prevSlug].title}
                            </Link>
                        ) : <div></div>}
                        
                        {nextSlug ? (
                            <Link 
                                to={`/blog/${nextSlug}`}
                                className="text-blue-900 hover:text-blue-700"
                            >
                                {blogPosts[nextSlug].title} →
                            </Link>
                        ) : <div></div>}
                    </div>
                </article>
            </div>
            <Footer />
        </>
    );
};

export default BlogPost; 