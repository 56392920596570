import { Link } from 'react-router-dom';
import NavBar from '../components/Navbar/NavBar';
import heroImg from '../images/banner.png';
import Button from 'react-bootstrap/Button';
import FancyVideoPopup from './utilities/FancyVideoPopup ';
import whtsapp from '../images/watsapp.png'
const Hero = () => {

  const  Pop_Up =()=>
    {
    <FancyVideoPopup videoUrl={"https://www.storyblocks.com/video/stock/bees-on-a-beautiful-yellow-sunflower-extract-pollen-close-up-shot-349085815"}></FancyVideoPopup>                              
    }
    return (
        <>
            <div className="hero" id='hero'>
                <div>
                    <NavBar />
                </div>
                
                <div className="m-auto overflow-hidden mx-4 mt-8 lg:mt-4 p-2 md:p-12 h-5/6" data-aos="zoom-in">

                    <div id='hero' className="flex flex-col mt-20 lg:flex-row py-8 justify-between text-center lg:text-left">
                        <div className="lg:w-1/2 flex flex-col justify-center" data-aos="zoom-in" data-aos-delay="200">
                            
                            <h1 className="mb-1 mt-8 md:text-5xl text-2xl font-bold text-blue-900">
                           
                            {/* We build digital solutions to help businesses scale */}
                               ምስላጥ ሓፈሻዊ ጉዳያት 
                            </h1>
                            <h1 className="mb-5 mt-0 md:text-5xl text-5xl font-bold text-green-500">
                           
                            ክብሪ መዝገብን ካልእን...
                            </h1>
                            <div className="text-xl font-semibold tracking-tight mb-5 text-black-500">
                                
                            አብ ዝተፈላለያ ሃገራት ንዝርከቡ ኤርትራውያን ን አብ ውሽጢ ሃገርን ወጻእን ከሳልጥዎ ን
                             ዝደልዩ ጉዳያት  ሓላፍነት ወሲዱ የሳልጥ። ብተወሳኺ ብዙሓት ዓይነት ዶኩመንታት ይልእኽን ይሰድድን።
                                
                                </div>
                              
                            <div className="mb-4 space-x-0 md:space-x-2 md:mb-8">
                                <Button  className="text-white bg-blue-900 hover:bg-blue-800 inline-flex items-center justify-center w-full px-6 py-3 my-4 text-lg shadow-xl rounded-2xl sm:w-auto sm:mb-0" onClick={Pop_Up}>
                                    ንዝበለጸ ብ ዋትስ ኣፕ ርኸቡና <p>-></p>
                                    
                                </Button>
                               
                             
                                { <Link to="" className="text-white bg-green-500 hover:bg-blue-800 inline-flex items-center justify-center w-full px-6 py-3 my-4 text-lg shadow-xl rounded-2xl sm:w-auto sm:mb-0">
                                    +291 7315541
                                    
                                </Link> }
                                {/* <a href="#_" className="inline-flex items-center justify-center w-full px-6 py-3 my-4 text-lg text-white bg-gray-500 hover:bg-gray-400 shadow-xl rounded-2xl sm:w-auto sm:mb-0">
                                    Learn More
                                    <svg className="w-4 h-4 ml-1" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9.663 17h4.673M12 3v1m6.364 1.636l-.707.707M21 12h-1M4 12H3m3.343-5.657l-.707-.707m2.828 9.9a5 5 0 117.072 0l-.548.547A3.374 3.374 0 0014 18.469V19a2 2 0 11-4 0v-.531c0-.895-.356-1.754-.988-2.386l-.548-.547z"></path></svg>
                                </a> */}
                            </div>
                        </div>
                        <div className="flex lg:justify-end w-full lg:w-1/2" data-aos="fade-up" data-aos-delay="700">
                            <img alt="kbri mezgeb,ክብሪ መዝገብ," className="rounded-t float-right duration-1000 mt-10 w-full" src={heroImg} />
                        </div>
                       
                    </div>
                </div>
            </div>
        </>
    )
}

export default Hero;